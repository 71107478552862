<template>
	<div>
		<b-modal :id="modalId"
			@close="closeModal"
			no-close-on-backdrop
			no-close-on-esc>
			<template #modal-title>
				Configure Vehicle Category
			</template>
			<div class="py-2">
				<div class="form mb-3">

					<div class="form-group">
						<div class="avatar avatar-xl">
							<img :src="imagePreview" class="avatar-img rounded-circle">
						</div>
						<p class="form-label mb-0 highlight" @click="$refs.imgUpload.click()">{{ this.form.image ? 'Change' : 'Attach' }} Image</p>
						<input
							type="file" ref="imgUpload" @change="uploadFile" accept=".png, .jpg, .jpeg"
							class="hidden d-block m-0 p-0" style="height: 0" />
					</div>

					<div class="form-group">
						<label class="form-label">Category Name</label>
						<input v-model="form.name" class="form-control mb-3" type="text">
						<div class="invalid-feedback" v-if="$v.form.name.$dirty && !$v.form.name.required">
							Please provide a vehicle category name.
						</div>
					</div>

					<div class="form-group">
						<label class="form-label">Description</label>
						<textarea rows="2" v-model="form.description" class="form-control mb-3" type="text"></textarea>
					</div>

					<div class="row">
						<div class="col-4">
							<div class="form-group">
								<label class="form-label">Seat Utilization <b-icon v-b-popover.click.top="'This figure is used to determine the percentage of total seats available that we need to sell to generate revenue on this vehicle type'" title="Seat Utilization" variant="info" icon="info-circle-fill"></b-icon> </label>
								<input v-model.number="form.break_even_utilization" placeholder="between 0.0 to 1.0" class="form-control mb-3" type="text">
								<div class="invalid-feedback" v-if="$v.form.break_even_utilization.$dirty && (!$v.form.break_even_utilization.maxValue || !$v.form.break_even_utilization.minValue)">
									Value should be between 0.0 to 1.0
								</div>
							</div>
						</div>
						<div class="col-4">
							<div class="form-group">
								<label class="form-label">Pricing margin</label>
								<input v-model.number="form.pricing_margin" :placeholder="`In ${form.pricing_margin_unit}`" class="form-control mb-3" type="text">
								<div class="invalid-feedback" v-if="$v.form.pricing_margin.$dirty && (!$v.form.pricing_margin.minValue)">
									Value should not be less than 0.0
								</div>
							</div>
						</div>
						<div class="col-4">
							<div class="form-group">
								<label class="form-label">Pricing margin unit</label>
								<select v-model="form.pricing_margin_unit" class="mb-3 custom-select">
									<option value="percent">Percent</option>
									<option value="flat">Flat Amount</option>
								</select>
								<div class="invalid-feedback" v-if="$v.form.pricing_margin_unit.$dirty && (!$v.form.pricing_margin_unit.oneOf)">
									Value should be one of percent or flat
								</div>

							</div>
						</div>
					</div>

					<div class="w-100 mt-3">
						<div v-for="(cityPricing, index) in form.cityPricing" :key="index" class="row city-pricing"
							:class="{'city-pricing--has-errors': $v.form.cityPricing.$each[index].$anyError}">
							<div class="form-group col-md-5 pr-0">
								<label class="form-label">City</label>
								<select
									class="custom-select"
									v-model="cityPricing.city_id">
									<option v-for="option of cities" :value="option.id" :key="option.city_id">{{option.name}}</option>
								</select>
								<div class="invalid-feedback"
									v-if="$v.form.cityPricing.$each[index].city_id.$dirty && !$v.form.cityPricing.$each[index].city_id.required">
									This field is required.
								</div>
								<div class="invalid-feedback"
									v-if="$v.form.cityPricing.$each[index].city_id.$dirty && !$v.form.cityPricing.$each[index].city_id.isUnique">
									City has to be unique.
								</div>
							</div>

							<div class="form-group col-md-5 pr-0">
								<label class="form-label">Cost of supply</label>
								<input
									v-model="cityPricing.cost_of_supply"
									required
									type="number"
									placeholder="N"
									class="form-control"
									/>
								<div class="invalid-feedback"
									v-if="$v.form.cityPricing.$each[index].city_id.$dirty && !$v.form.cityPricing.$each[index].city_id.required">
									This field is required.
								</div>
							</div>

							<div class="form-group col-md-2 remove-column">
								<i
									class="fe fe-x-circle fa-lg remove_"
									style="color: red"
									@click="removeCityPricing(index)"
									></i>
							</div>

						</div>
					</div>

					<div class="w-100">
						<button class="btn btn-sm btn-add-city" @click="addPricing()">
							<i class="fe fe-plus"></i>
							Add City
						</button>
					</div>

				</div>

			</div>
			<template #modal-footer>
				<div class="w-100">
					<b-button
						:disabled="loading"
						variant="primary"
						class="float-right"
						@click="save()">
						{{ loading ? 'Processing' : 'Save' }}
					</b-button>
					<b-button
						:disabled="loading"
						variant="secondary"
						class="float-right mr-2"
						@click="closeModal()">
						Close
					</b-button>
				</div>
			</template>
		</b-modal>
	</div>
</template>

<script>
import required from 'vuelidate/lib/validators/required'
import { maxValue, minValue, or, sameAs } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { extractErrorMessage } from '@/utils/helpers'
import { lookupService } from '@/services/lookup'
function isUniqueCity (value, vm) {
  if (value === '') { return true }
  if (vm.form.cityPricing?.length) { return !(vm.form.cityPricing.filter((o) => o.city_id === value).length > 1) }
  return true
}
export default {
  name: 'VehicleCategorySetup',
  mixins: [validationMixin],
  props: {
    show: Boolean,
    model: Object
  },
  data () {
    return {
      form: {
        id: null,
        name: null,
        description: null,
        image: null,
        pricing_margin: 10,
        pricing_margin_unit: 'percent',
        break_even_utilization: 1.0,
        cityPricing: []
      },
      removedPricing: [],
      cities: [],
      modalId: 'vehicle-category-setup-modal',
      loading: false,
      newAttachment: null
    }
  },
  validations: {
    form: {
      break_even_utilization: {
        maxValue: maxValue(1.0),
        minValue: minValue(0.0)
      },
      pricing_margin: {
        minValue: minValue(0.0)
      },
      pricing_margin_unit: {
        oneOf: (value) => ['percent', 'flat'].indexOf(value) > -1
      },
      name: {
        required
      },
      cityPricing: {
        $each: {
          city_id: { required, isUnique: function (value) { return isUniqueCity(value, this) } },
          cost_of_supply: { required }
        }
      }
    }
  },
  watch: {
    show: {
      handler: function (value) {
        if (value) {
          this.openModal()
        } else {
          this.closeModal()
        }
      }
    }
  },
  async created () {
    this.cities = await lookupService.fetchCities()
  },
  methods: {

    patchForm (data) {
      if (data) {
        this.form.id = data?.id
        this.form.name = data?.name
        this.form.image = data?.image
        this.form.break_even_utilization = data?.break_even_utilization
        this.form.pricing_margin = data?.pricing_margin
        this.form.pricing_margin_unit = data?.pricing_margin_unit
        this.form.description = data?.description
        this.form.cityPricing = []
        if (data.cityPricing) {
          data.cityPricing.forEach((pricing) => {
            this.addPricing(pricing)
          })
        }
      } else {
        this.resetForm()
      }
    },
    resetForm () {
      this.form.id = null
      this.form.name = null
      this.form.description = null
      this.form.image = null
      this.form.cityPricing = []
      this.newAttachment = null
      this.removedPricing = []
    },
    openModal () {
      this.$bvModal.show(this.modalId)
      if (this.model) {
        this.patchForm(this.model)
      }
    },
    closeModal () {
      this.$bvModal.hide(this.modalId)
      this.$emit('close')
      this.resetForm()
      this.$v.form.$reset()
      this.$v.form.name.$reset()
    },
    removeCityPricing (index) {
      this.removedPricing.push(...this.form.cityPricing.splice(index, 1))
    },
    async savePricingChanges (vehicleTypeId, description, cityPricingData) {
      const updateRecords = cityPricingData.filter((config) => !!config.id).map((obj) => {
        return {
          ...obj,
          vehicle_type_id: vehicleTypeId,
          description
        }
      })
      const createRecords = cityPricingData.filter((config) => !config.id).map((obj) => {
        return {
          ...obj,
          vehicle_type_id: vehicleTypeId,
          description
        }
      })
      try {
        await Promise.all(createRecords.map((obj) => this.$axios.post('/v1/vehicle-types/cost-of-supply', obj)))
        await Promise.all(updateRecords.map((obj) => this.$axios.patch(`/v1/vehicle-types/cost-of-supply/${obj.id}`, obj)))
        await Promise.all(this.removedPricing.map((obj) => this.$axios.delete(`/v1/vehicle-types/cost-of-supply/${obj.id}`)))
      } catch (e) {
      }
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      const payload = {
        name: this.form.name,
        description: this.form.description
      }

      if (typeof this.form.break_even_utilization === 'number') {
        payload.break_even_utilization = this.form.break_even_utilization
      }

      if (typeof this.form.pricing_margin_unit === 'string') {
        payload.pricing_margin_unit = this.form.pricing_margin_unit
      }

      if (typeof this.form.pricing_margin === 'number') {
        payload.pricing_margin = this.form.pricing_margin
      }

      if (this.newAttachment) {
        payload.image = this.newAttachment
      }
      const request = this.form.id
        ? this.axios.patch(`/v1/vehicle-types/${this.form.id}`, payload)
        : this.axios.post('/v1/vehicle-types', payload)
      this.loading = true
      try {
        // Save request for vehicle category
        const response = await request
        // Save request for city pricing
        if (response && response.data && this.form.cityPricing.length) {
          await this.savePricingChanges(response.data.id, response.data.name, this.form.cityPricing)
        }
        this.$emit('config-saved')
        this.$toastr.s('Category saved successfully!')
        this.closeModal()
      } catch (e) {
        const errMsg = extractErrorMessage(e)
        this.$toastr.e(errMsg, 'Failed to save configuration!')
      } finally {
        this.loading = false
      }
    },

    uploadFile () {
      this.file = this.$refs.imgUpload.files[0]
      const reader = new FileReader()
      reader.addEventListener('load', (event) => {
        this.form.image = this.newAttachment = event.target.result
      })
      reader.readAsDataURL(this.file)
    },
    addPricing (cityPricingData) {
      this.form.cityPricing.push({
        id: cityPricingData?.id || null,
        vehicle_type_id: cityPricingData?.vehicle_type_id || null,
        city_id: cityPricingData?.city_id || null,
        cost_of_supply: cityPricingData?.cost_of_supply || null,
        currency: cityPricingData?.currency || 'NGN'
      })
    }
  },
  computed: {
    imagePreview () {
      return this.form.image ? this.form.image : require('@/assets/img/default-bus.png')
    }
  }
}
</script>

<style lang="scss" scoped>
@use "src/assets/scss/partials/sh-colors" as colors;
.invalid-feedback {
  display: block;
}

.hidden {
  visibility: hidden;
}
.highlight {
  cursor: pointer;
  &:hover{
    color: #0DAC5C;
  }
}

.btn-add-city {
  padding: 0.25rem 0.5rem;
  border: 1px solid colors.$sh-gray-4;
  border-radius: 0.5rem;
  background: white;
  color: colors.$sh-gray-4;
}

.invalid-feedback {
  display: block;
}

.city-pricing {
  label {
    margin-bottom: 0.25rem;
  }
  .remove-column {
    padding-bottom: 0.5rem;
    display: flex;
    align-items: flex-end;
  }
  .form-group {
    margin-bottom: 0;
  }

  margin-bottom: 1rem;

  &--has-errors {
    .remove-column {
      align-items: center;
    }
  }
}
</style>
