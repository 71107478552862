<template>
  <div class="main-content">
    <page-header title="Vehicle" pre-title="Overview" :loading="false">
      <template slot="actions">
        <div class="flex gap-3">

          <!-- <button class="btn btn-outline-primary" @click="showInspectionModal = true" v-if="userHasPermission('inspection001')">New Inspection Site</button> -->
          <button class="btn btn-outline-primary" @click="showInspectionModal = true">New Inspection Site</button>
          <button @click="openSetupModal()" class="btn btn-primary">
            New Vehicle Category
          </button>
        </div>
      </template>
      <template slot="tabs">
        <div class="container">
          <div class="pt-3 pb-1 row">
            <div class="col justify-self-center align-self-center flex-column">
              <ul class="nav nav-pills">
                <li class="nav-item">
                  <router-link :to="{ name: 'CategoryManagement' }" class="nav-link" exact-active-class="active">Category Management</router-link>
                </li>
                <!-- <li class="nav-item" v-if="userHasPermission('inspection003')"> -->
                <li class="nav-item">
                  <router-link :to="{ name: 'InspectionSites' }" class="nav-link" exact-active-class="active">Inspection Sites</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </template>
    </page-header>

    <page-route-view :showBackBtn="false" :loading="false">
    </page-route-view>

    <vehicle-category-setup @close="showSetupModal = false" @config-saved="window.reload()" :model="selectedCategory" :show="showSetupModal">
    </vehicle-category-setup>

    <BS_modal :show='showInspectionModal' title='Create Inspection Site' @close='showInspectionModal = false' modalId='InspectionModal'>
      <div class="col-12">
        <div class="relative form-group">
          <label class="mb-1">Name</label>
          <input required type="text" class="form-control" v-model='createSiteForm.name.value' />
        </div>
        <div class="relative form-group">
          <label class="mb-1">Address</label>
          <vue-google-autocomplete  id="startingPointAddressInput" classname="form-control z-[999999]" placeholder="Starting Point" v-on:placechanged="originChanged" types="" country="ng">
          </vue-google-autocomplete>
        </div>
      </div>

      <div class="col-12">
        <button class='w-full text-white btn bg-dark' @click='createSite' :disabled="createSiteForm.name.value === '' || createSiteForm.address.value === '' || createSiteLoading">
          {{ createSiteLoading.value ? 'loading...' : 'Create Site' }}
        </button>
      </div>
    </BS_modal>


  </div>
</template>



<script setup>
import PageHeader from '@/components/layout/PageHeader'
import PageRouteView from '@/components/layout/PageRouteView'
import { userHasPermission } from '@/composables/core/permissions'
import VehicleCategorySetup from '@/views/AdminConfig/CategoryManagement/VehicleCategorySetupModal'
import { extractErrorMessage } from '@/utils/helpers'
import BS_modal from '@/components/core/modals/BS_modal.vue'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import defaultBusImage from '@/assets/img/default-bus.png'
import { ref, watch, onMounted, reactive, toRefs } from 'vue'
import { axiosInstance } from '@/plugins/axios'
import Swal from 'sweetalert2'
import { useInspectionSite } from './composable'

const showInspectionModal = ref(false)
const createSiteLoading = ref(false)
const {getSites} = useInspectionSite()
const createSiteForm = {
  name: ref(''),
  address: ref(''),
  geo_coordinate: ref(''),
}
const originChanged = (_, place) =>{
  createSiteForm.address.value = place.formatted_address
  createSiteForm.geo_coordinate.value = `${place.geometry.location.lat()},${place.geometry.location.lng()}`
}

const createSite = ()=>{
  axiosInstance.post('/v1/inspection-sites', {
    name: createSiteForm.name.value,
    address: createSiteForm.address.value,
    geo_coordinate: createSiteForm.geo_coordinate.value,
  })
    .then((res)=>{
      showInspectionModal.value = false
      Swal.fire({ icon: 'success', title: 'Success', text: 'Inspection site created successfully' })
      createSiteForm.name.value = ''
      createSiteForm.address.value = ''
      createSiteForm.geo_coordinate.value = ''
      getSites()
    })
    .catch((err)=>{
      const msg = extractErrorMessage(err, 'An error occurred please try again later.')

      Swal.fire({ icon: 'error', title: 'Error', text: msg ?? 'An error occured'})
    })
    .finally(()=>{
      createSiteLoading.value = false
    })
}
const state = reactive({
  currentPage: 1,
  perPage: 10,
  loading: false,
  showSetupModal: false,
  showDeleteModal: false,
  deletingCategory: false,
  verifyingDelete: false,
  verificationMessage: '',
  selectedCategory: null,
  totalRecords: 0,
  tableData: [],
  defaultBus: defaultBusImage,
})



function openSetupModal(data = null)
{
  state.selectedCategory = data
  state.showSetupModal = true
}


// Expose reactive properties as refs for template access
const {

  showSetupModal,

  selectedCategory,

} = toRefs(state)
</script>

<style>
.pac-container{
  z-index: 10000000;
}
</style>

<style lang="scss" scoped>
@import "../../../assets/scss/nav-tabs";

//TODO: Apply styling to all tables based on design guide
.table-responsive {
  margin-bottom: 0;
}

::v-deep {
  .b-table {
    & thead {
      tr>th {
        background: transparent;
      }
    }
  }
}

.b-pagination {
  margin-bottom: 0;
}
</style>
